.dashboard-main-wrapper {
    padding: 16px;
}

.project-item-table-header{
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-item-table-header p {
    margin: 0px;
}