.users-main-wrapper {
    margin-top: 32px;
}

.edit-btn {
    max-width: 105px !important;
    min-height: 22px !important;
}

.create-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
}

.create-btn {
    min-width: 150px !important;
}

Label {
    color: #333;
    font-weight: bold;
}

.modal-class {
    color: #333;
}

.edit-btn-wrapper {
    display: flex;
    justify-content: space-between;
}

.edit-icons {
    cursor: pointer;
    margin: 0px 12px 0px 12px;
}

.warning {
    color: rgb(221, 22, 22);
}