.main-nav-wrapper {
    height: 100vh;
    padding: 0px;
}

.icon-wrapper {
    display: flex;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 12px;
    border-bottom: 2px solid #333;
    padding: 16px;
}

.icon-wrapper:hover {
    background-color: rgba(51, 51, 51, 0.082);
    border-radius: 8px;

}

.icon-wrapper p {
    margin: 0px;
    width: 100%;
}

.nav-wrapper {
    cursor: pointer;
    margin-bottom: 10px;
}

.nav-icon {
    margin-right: 12px;
    height: 25px;
    width: 25px;
}