.new-project-main-wrapper{
    margin-top: 32px;
    color: #333;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 16px 16px 16px 16px;
}

.mid-section {
    min-height: 500px;
}

.bottom {
    margin-top: 16px;
}

.back {
    justify-content: space-between !important;
}

.user-toggle-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-list-wrapper {
    margin-top: 16px;
    color: #333;
    border: 2px solid #333;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px;
}

.user-list-wrapper p {
    margin: 0px;
}

.invited-users-label-wrapper {
    display:flex;
    justify-content: space-between;
    margin-top: 32px;
}

.text-area-wrapper {
    margin-top: 6px;
    width: 100%;
}

.cursor {
    cursor: pointer;
}

.remove-btn-wrapper {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.file-upload-wrapper {
    border: solid 1px #333;
    padding: 5px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.file-upload-wrapper p {
    margin: 0px;
}

.reset-upload-btn {
    width: 150px;
    margin-top: 32px;
    margin-left: 12px;
}

.uploading-status-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;  
  flex-wrap: wrap;
}

.invite-users-wrapper {
    min-height: 150px;
    padding: 20px;
    border-radius: 8px;
    border: solid 1px #333;
    margin-right: 20px;
}

.inner-invite-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.selec-all {
    min-width: 150px;
    max-width: 150px;
    margin-right: 10px;
}

.select-btn-wrapper {
    margin: 24px 0px 24px 0px;
    display: flex;
}

.invite-user-col {
    padding: 6px;
    border: 1px solid #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-radius: 8px;
    margin: 6px;
    min-width: 350px;
    max-width: 350px;
}

.toggle-col {
    display: flex;
}

.invite-user-col p {
    margin: 0px;
}

.menu-scroll {
    max-height: 350px;
    overflow: auto
}

.email-action {
    margin: 0;
    padding: 3px;
    background-color: orange;
    color: #333;
    cursor: pointer;
    justify-content: space-around;
    border-radius: 8px;
    display: flex;
    align-items: center;
}