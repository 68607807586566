.main-row {
    min-height: 85vh;
}

.page-col {
    margin: 0px 0px 0px 0px;
    padding-right: 0px;
    padding-left: 0px;
}

.nav-col {
    padding: 16px 0px 0px 0px;
    border-right: 2px solid #333;
}

.header {
    background-color: #FFF;
    width: 98%;
    height: 65px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
}

.welcome-wrapper p {
    margin: 0px;
    text-align: center;
}

.account-type {
    font-weight: bold;
}