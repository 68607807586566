.login-main-wrapper {
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
}

.inner-login-wrapper {
    padding: 32px;
    border-radius: 8px;
    border: solid 1px #FFF;
    width: 400px;
}

.login-btn-wrapper {
    display: flex;
    justify-content: center;
}

.error-wrapper {
    margin-top: 16px;
    font-size: .8em;
    text-align: center;

}

.title-wrapper{
    margin-bottom: 32px;
}

.title-wrapper h1 {
    text-align: center;
}

.forgotten-password {
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}

.btn-primary {
    min-width: 100% !important;
}