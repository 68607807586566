.view-main-wrapper {
    margin-top: 32px;
    color: #333;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 16px 16px 16px 16px;
    margin-bottom: 32px;
}

.add-file-wrapper {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
}
.upload-div {
    display: flex;
    width: 100%;
}

.files-wrapper {
    padding: 20px;
    border: solid 1px #333;
}

.upload-div label {
    margin-right: 230px;
}

.clear-btn {
    margin-bottom: 16px;
    height: 25px;
}

.user-search {
    background-color: #FFF;
    min-height: 150px;
    border: solid 1px #333;
    padding: 12px;
    position: absolute;
    width: 80%;
}

.user-search p {
    margin-bottom: 15px;
    cursor: pointer;
    border-bottom: 1px solid #333;
    padding: 5px;
}

.file-download {
    padding: 12px;
    background-color: rgb(32, 201, 173);
    max-width: 350px;
    min-width: 250px;
    cursor: pointer;
    color: #FFF;
    border-radius: 8px;
    text-align: center;
}

.file-delete {
    padding: 12px;
    background-color: rgb(219, 18, 18);
    max-width: 100px;
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    color: #FFF;
    border-radius: 8px;
}

.invited-user {
    padding: 12px;
    background-color: rgb(32, 201, 173);
    max-width: 350px;
    min-width: 250px;
    color: #FFF;
    border-radius: 8px;
    text-align: center;
}

.email-history {
    padding: 12px;
    background-color: rgb(32, 111, 201);
    max-width: 350px;
    min-width: 150px;
    color: #FFF;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
}

.email-send {
    padding: 12px;
    background-color: rgb(37, 192, 76);
    max-width: 350px;
    min-width: 150px;
    color: #FFF;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
}

.delete-user {
    padding: 12px;
    background-color: rgb(219, 18, 18);
    max-width: 100px;
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    color: #FFF;
    border-radius: 8px;
    margin-left: 100px;
}

.end-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.upload-box {
    display: flex;
    flex-wrap: wrap;
}

.button-box {
    display: flex;
    justify-content: space-between;
}

.submitted-wrapper {
    display: flex;
    justify-content: center;
    margin: 32px 0px 16px 0px;
}

.submission-row {
    display: flex;
    padding: 8px 60px 8px 60px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-radius: 8px;
    border: solid 1px #333;
    margin: 6px 12px 6px 12px;
}

.submission-row-buttons {
    max-height: 35px;
    min-width: 75px;
    min-height: 35px;
}

.loader {
    display: flex;
    width: 100%;
    justify-content: center;
}

.invited-users-main-wrapper {
    max-height: 450px;
    overflow: auto;
}

.center-loading {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.email-history-modal-body {
    max-height: 500px;
    overflow: auto;
}

.email-history-wrapper {
    border: 1px solid #333;
    padding: 6px;
    border-radius: 8px;
    margin-top: 16px;
}