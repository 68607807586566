.projectitem-row {
    min-height: 65px;
    width: 100%;
    background-color: #FFF;
    border-radius: 8px;
    color: #333;
    box-shadow: 0 8px 6px -6px black;
    margin: 16px 0px 16px 0px;
}

.projectitem-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectitem-col p {
    margin: 0px;
}

.projectitem-btn {
    max-height: 45px;
    min-height: 45px;
}

.projectitem-badge-wrapper-new{
    background-color: red;
    font-weight: bold;
    border-radius: 8px;
    padding: 6px 12px 6px 12px;
    color: #FFF;
    font-size: .6em;
    
}

.projectitem-badge-wrapper {
    color: #FFF;
    font-size: .6em;
}

.projectitem-badge-wrapper-awarded {
    background-color: #5cb85c !important;
    font-weight: bold;
    border-radius: 8px;
    padding: 6px 12px 6px 12px;
    color: #FFF;
    font-size: .6em;
}

.projectitem-badge-wrapper-active {
    background-color: #5cb85c !important;
    font-weight: bold;
    border-radius: 8px;
    padding: 6px 12px 6px 12px;
    color: #FFF;
    font-size: .6em;
}