.project-main-wrapper {
    margin-top: 32px;
}

.create-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
}

.create-btn {
    min-width: 150px !important;
}

.submitter-name {
    display: flex;
}

.tick-icon {
    margin-right: 6px;
}