body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34 !important;
  font-size: 1em;
  color: #fff;
}

input {
  min-height: 50px;
  margin: 16px 0px 16px 0px;
}

.btn-primary {
  min-height: 50px;
  min-width: 180px;
  color: #fff;
  background-color: rgb(46, 138, 28);
  border-color: rgb(46, 138, 28);
}


.btn-primary:hover {
  min-height: 50px;
  min-width: 180px;
  color: #fff;
  background-color: rgba(46, 138, 28, 0.74);
  border-color: rgba(46, 138, 28, 0.74);
}

.btn-primary:active {
  min-height: 50px;
  min-width: 180px;
  color: #fff;
  background-color: rgba(46, 138, 28, 0.74);
  border-color: rgba(46, 138, 28, 0.74);
}

.btn-primary:focus {
  min-height: 50px;
  min-width: 180px;
  color: #fff;
  background-color: rgba(46, 138, 28, 0.74);
  border-color: rgba(46, 138, 28, 0.74);
}

.btn-secondary {
  min-height: 50px;
  min-width: 180px;
}

.btn-danger {
  min-height: 50px;
  min-width: 180px;
}

.btn-success {
  min-height: 50px;
  min-width: 180px;
}

.sr-only {
  display: none;
}

select {
    min-height: 55px;
    margin: 16px 0px 16px 0px;
    cursor: pointer;
}

.nav-item {
  cursor: pointer;
}

.white-text {
  color: #fff !important;
}
