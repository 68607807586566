.speciality-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 6px 0 6px 0px;

}

.speciality-main-wrapper {
    max-height: 450px !important;
    overflow: auto;
}

.speciality-wrapper p {
    margin: 0;
}

.speciality {
    padding: 12px;
    background-color: rgb(32, 201, 173);
    max-width: 350px;
    min-width: 250px;
    color: #FFF;
    border-radius: 8px;
    text-align: center;
}

.speciality-delete {
    padding: 12px;
    background-color: rgb(219, 18, 18);
    max-width: 100px;
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    color: #FFF;
    border-radius: 8px;
}