.pw-reset {
    max-width: 180px !important;
    min-width: 180px !important;
}

.forgotpw-header {
    color: #333;
}

.forgotpw-body {
    padding: 30px 24px 24px 30px;
}